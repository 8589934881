export async function getSantasCount() {
    try {
        const apiUrl = process.env.NEXT_PUBLIC_DRUPAL_API_URL;
        const response = await fetch(`${apiUrl}/api/capacity`);
        
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        
        const data = await response.json();

        return {
            status: true,
            count: data.capacity,
        };
    } catch (error) {
        console.error('Error fetching message:', error);
        return { 
            status: false,
        };
    }
}
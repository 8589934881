// import '../../styles/InformationPromptView.css'

export default function InformationPromptView({ isGiver }) {
    return (
        <>
            <div id="information-prompt">
                <div id="icon"></div>
                { isGiver && 
                    <>
                        Die Person, die diese Nachricht erhält, bekommt eine Einladung zur Cashback Aktion für einen Milka Weihnachtsmann.
                    </>
                }

                { !isGiver && 
                    <>
                        Schaue dir die ganze Nachricht an und mach bei unserer Cashback Aktion mit.​<br /><br /><br />
                    </>
                }
                
            </div>
        </>
    )
}

export async function getMessage(hash) {
    try {
        const apiUrl = process.env.NEXT_PUBLIC_DRUPAL_API_URL;
        const publicUrl = process.env.NEXT_PUBLIC_URL;

        const response = await fetch(`${apiUrl}/api/videos/${hash}?receiver=1`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        const sampleCode = typeof data.code === 'string' ? data.code : "0";

        return {
            status: true,
            contentUrl: data.contentUrl,
            maskUrl: data.maskUrl,
            fileWithMaskUrl: data.fileWithMaskUrl,
            audio: data.audio,
            videoCheckedAt: data.videoImageNsfwDetectedAt || '',
            videoNsfwScore: data.videoImageNsfw || '',
            audioCheckedAt: data.containsBadWordDetectedAt || '',
            audioBadWords: data.containsBadWord || '',
            sampleCode: sampleCode
        };
    } catch (error) {
        console.error('Error fetching message:', error);
        return { 
            status: false,
        };
    }
}